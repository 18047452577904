.frame-distrib {
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 500px;
    
}

.distrib-zone-texte {
    width: 100%;
    height: 350px;
    
    background-color: rgba(175, 59, 40, 0.82) ;
    position: relative;
    top: 75px;
    
    box-shadow: 0px 12px 4px -2px rgba(0,0,0,0.25);
}

.frame-ditrib span {
    color: white;
    
    font-family: "Josefin Sans";
    display: block; 
    margin: 0 auto;
}

.distrib-titre {
    font-weight: bold;
    text-align: center;
    font-size: 60px;
    position: relative;
    top: 10%;
    
}

.distrib-msg {
    font-style: italic;
    text-align: center;
    font-size: 38px;
    position: relative;
    top: 16%;
}


.distrib-btn {
    background-color: rgba(255, 255, 255, 1);
    /* border: 5px solid rgb(33, 128, 142); */
    border: none;
    color: rgb(33, 128, 142);
    cursor: pointer;
    border-radius: 50px;
    position: absolute;
    height: 70px;
    text-align: center;
    width: 30%;
    left: 35%;
    bottom: 10%;
    min-width: 350px;
    font-family: "Josefin Sans";
    font-weight: bold;
    font-size: 50px;
}


.distrib-btn:hover {
    border: 5px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    background-color: rgba(33, 127, 142, 1);
}

@media screen and (max-width : 1350px) {
    .frame-distrib {
        height: 420px;
    }

    .distrib-zone-texte {
        height: 300px;
        top: 50px;
    }

    .distrib-titre {
        font-size: 45px;
    }

    .distrib-msg {
        font-size: 30px;
    }

    .distrib-btn {
        font-size: 35px;
        min-width: 250px;
        height: 60px;
        
    }
}



@media screen and (max-width : 1000px) {
    .frame-distrib {
        height: 300px;
    }

    .distrib-zone-texte {
        height: 220px;
        top: 35px;
    }

    .distrib-titre {
        font-size: 35px;
    }

    .distrib-msg {
        font-size: 20px;
    }
    .distrib-btn{
        font-size: 25px;
        min-width: 200px;
        height: 50px;
        
    }

}



@media screen and (max-width : 800px) {
    .frame-distrib {
        height: 250px;
    }

    .distrib-zone-texte {
        height: 180px;
        top: 35px;
    }

    .distrib-titre {
        font-size: 28px;
    }

    .distrib-msg {
        top : 10%;
        font-size: 18px;
    }

    .distrib-btn{
        font-size: 130%;
        min-width: 150px;
        height: 40px;
        
    }
}

@media screen and (max-width : 600px) {
    
    .distrib-zone-texte {
        
        height: 150px ;
    }
    
    .distrib-titre {
        font-size: 18px;
    }

    .distrib-msg {
        top : 12%;
        font-size: 13px;
    }

    .distrib-btn{
        font-size: 12px;
        min-width: 100px;
        height: 35px;
        bottom: 8%;
        /* border: 3px solid rgb(33, 128, 142); */
    }
    .distrib-btn:hover{
        
        border: 3px solid rgb(255, 255, 255);
    }
}