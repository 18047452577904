.distribpage-frame {
    position: relative;
}

.distribpage-frame span {
    color:  rgba(175, 59, 40, 0.82);
}


.distribpage-titre {
    font-size: 50px;
    margin-top: 2%;
    margin-left: 5%;
    font-weight: bold;

}

.distribpage-msg-alternatif {
    font-size: 30px;
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: center;
}

.distribpage-msg-alternatif a{
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    color: rgb(33, 128, 142);
    border: 3px solid rgb(33, 128, 142);
    padding: 9px;
    border-radius: 90px;
    font-style: normal;
}

.distribpage-msg-alternatif a:hover{
    
    color: white ;
    border: 3px solid rgb(33, 128, 142);
    background-color: rgb(33, 128, 142);
    
}

@media screen and (max-width : 1350px) {
    .distribpage-titre {
        font-size: 40px;
    }
    .distribpage-msg-alternatif {
        font-size: 25px;
    }
}


@media screen and (max-width : 1000px) {
    .distribpage-titre {
        font-size: 30px;
    }
    .distribpage-msg-alternatif {
        font-size: 20px;
    }
}


@media screen and (max-width : 800px) {
    .distribpage-titre {
        font-size: 25px;
    }
    .distribpage-msg-alternatif {
        font-size: 16px;
    }
}

@media screen and (max-width : 600px) {
    .distribpage-titre {
        font-size: 20px;
    }
    .distribpage-msg-alternatif {
        font-size: 16px;
        margin-top: 2%;
    }
}