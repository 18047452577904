.errorpage-frame span {
    color: #B54129;
    text-align: center;
}



.errorpage-titre {
    font-size: 60px;
    font-weight: bold;
}

.errorpage-texte {
    margin-top: 30px;
    width: 75%;
    font-size: 40px;
    font-weight: 300;
}


@media screen and (max-width : 1350px) {
    .errorpage-titre {
        font-size: 45px;
    }
    .errorpage-texte {
        font-size: 32px;
    }
}


@media screen and (max-width : 1000px) {
    .errorpage-titre {
        font-size: 35px;
    }
    .errorpage-texte {
        font-size: 25px;
    }
}


@media screen and (max-width : 800px) {
    .errorpage-titre {
        font-size: 27px;
    }
    .errorpage-texte {
        font-size: 20px;
    }
}

@media screen and (max-width : 600px) {
    .errorpage-titre {
        font-size: 23px;
    }
    .errorpage-texte {
        font-size: 19px;
    }
}