.biereslistpage-frame {
    background-image: url("../assets/Sans_facon_VECTORISE.svg");
    background-repeat: repeat;
    
    position: relative;
}

.biereslistpage-titre {
    font-size: 80px;
    background-color: white;
    position: relative;
    color: black;
    font-weight: bold;
    display: block;
    width: fit-content;
    padding: 10px;
    border: 5px solid black;
}

.biereslistpage-container {
    position: relative;
    width: 90%;
    margin: 1% auto 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
    gap: 40px;
}


@media screen and (max-width : 1350px) {
    .biereslistpage-titre {
        font-size: 50px;
    }
}


@media screen and (max-width : 1000px) {
    .biereslistpage-titre {
        font-size: 45px;
    }

    
}


@media screen and (max-width : 800px) {
    .biereslistpage-titre {
        font-size: 40px;
    }

    .biereslistpage-frame {
        background-size: cover;
    }
}

@media screen and (max-width : 600px) {
    .biereslistpage-titre {
        font-size: 35px;
    }

    .biereslistpage-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
    }
}