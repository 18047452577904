.footer-frame {
    width: 100%;
    height: 130px;
    background-color: black;
    position: relative;
    bottom: 0%;
    
}

.footer-frame a {
    color: inherit;
    text-decoration: none;
    
}

.footer-instagram {
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 1%;
    
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    
    
}

.footer-instagram-logo {
    height: 60px;
    
    
}

.footer-instagram-texte {
    
    font-family: "Josefin Sans";
    font-style: italic;
}

.footer-adresse {
    position: absolute;
    bottom: 4%;
    left: 3%;
    font-family: "Josefin Sans";
    font-size: 15px;
    
}


.footer-droits {
    position: absolute;
    display: block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    bottom: 2%;
}

.footer-droits-texte {
    font-family: "Josefin Sans";
    font-style: italic;
    color: white;
    font-size: 20px;
}

.footer-mentions {
    width: 100%;
    height: 20px;
    text-align: center;
    position: absolute;
    top : 2%

}

.footer-mention-texte {
    display: inline-block;
    font-size: 17px;
}

.footer-mention-lien {
    display: inline-block;
    cursor: pointer;
}
.footer-mention-lien:hover {
    color: rgb(109, 109, 109);
}


@media screen and (max-width : 1350px) {
    
    .footer-adresse {
        font-size: 13px;
    }

    .footer-droits-texte {
        font-size: 16px;
    }
}
@media screen and (max-width : 1000px) {
    
    .footer-instagram {
        position: relative;
        
        margin: 5px auto 2px auto;
    }

    .footer-mentions {
        position: relative;
    }

    .footer-adresse {
        position: relative;
        font-size: 13px;
        width: fit-content;
        margin: 0 auto 15px auto;
        text-align: center;

    }

    .footer-droits-texte {
        font-size: 16px;
    }
    
    

    .footer-mention-texte {
        font-size: 15px;
        margin-top: 15px;
    }
}

@media screen and (max-width : 800px) {
    
    .footer-adresse {
        font-size: 13px;
        
        
    }
    
    
    .footer-droits-texte {
        font-size: 13px;
    }

    .footer-mention-texte {
        font-size: 12px;
    }
    
}


