.histoire-frame {
    height: 500px;
    width: 100%;
    background-color: white;
    border-top: 5px solid rgba(175, 59, 40, 0.82);
    position: relative;
    
    
}

.histoire-backgroundForm {
    height: 100%;
    width: 100%;
    -webkit-clip-path: polygon(67% 0, 100% 0, 100% 100%, 45% 100%);
    clip-path: polygon(67% 0, 100% 0, 100% 100%, 45% 100%);
    background-color: rgba(175, 59, 40, 0.82);
}


.histoire-texte {
    font-size: 40px;
    font-family: "Josefin Sans";
    color: rgba(175, 59, 40, 0.82);
    
    position: relative;
    width: max-content;
    font-style: italic;
    max-width: 82%;
    margin: 15px 0;
    left: 10%;
    top: 3%;
}

.histoire-txt-container {
    width: 55%;
    height: 60%;
    margin: auto 0;
    position: absolute;
    top : 20%;
}

.histoire-photo {
    width: 80%;
    position: relative;
    display: block;
    margin: 0 auto;
    border: 4px solid white;
    border-radius: 90%;
    max-width: 350px;
    
}

.histoire-photo-container {
    width: 25%;
    height: 80%;
    
    position: absolute;
    top : 5%;
    right: 5%;
    
}

.histoire-photo-legende {
    text-align: center;
    font-size: 25px;
    font-family: "Josefin Sans";
    margin-top: 3%;
}



@media screen and (max-width : 1350px) {
    .histoire-frame {
        height: 400px;
    }
    
    .histoire-texte {
        font-size: 30px;
    }

    .histoire-photo-legende {
        font-size: 20px;
    }
}


@media screen and (max-width : 1000px) {
    .histoire-frame {
        height: 315px;
    }
    
    .histoire-texte {
        font-size: 22px;
    }

    .histoire-photo-legende {
        font-size: 15px;
    }

    .histoire-photo-container {
        width: 30%;
    }
    .histoire-photo {
        width: 70%;
        border: 3px solid white;
    }

}


@media screen and (max-width : 800px) {
    .histoire-frame {
        height: 260px;
    }
    
    .histoire-texte {
        font-size: 17px;
    }

    .histoire-photo-legende {
        font-size: 12px;
    }

    .histoire-photo-container {
        width: 30%;
    }
    .histoire-photo {
        width: 80%;
        
    }
}


@media screen and (max-width : 600px) {
    .histoire-frame {
        height: 220px;
    }
    
    .histoire-texte {
        font-size: 15px;
    }

    .histoire-photo-legende {
        font-size: 10px;
    }

    .histoire-photo-container {
        width: 30%;
        top : 20%
    }

    .histoire-backgroundForm {
        
        -webkit-clip-path: polygon(67% 0, 100% 0, 100% 100%, 50% 100%);
        clip-path: polygon(67% 0, 100% 0, 100% 100%, 50% 100%);
        
    }

    .histoire-photo {
        width: 80%;
        border: 2px solid white;
    }

    .histoire-txt-container {
        top : 10%
    }
}