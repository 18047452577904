

.event-frame {
    height: 600px;
    width: 100vw;
    overflow: hidden;
    background-color: white;
    position: relative;
}

.event-titre {
    font-size: 60px;
    font-family: "Josefin Sans";
    color: rgba(175, 59, 40, 0.82);
    font-weight: bold;
    position: absolute;
    width: max-content;
    left: 5%;
    top: 1%;
    max-width: 80%;
}

.event-texte {
    font-size: 40px;
    font-family: "Josefin Sans";
    color: rgba(175, 59, 40, 0.82);
    
    position: relative;
    width: max-content;
    max-width: 82%;
    margin: 15px 0;
    left: 10%;
    top: 7%;

}



.event-texte a {
    
    font-weight: bold;
    color: rgba(175, 59, 40, 0.82);
    text-decoration: none;
    
    
}

.event-bullet-list {
    font-size: 40px;
    font-family: "Josefin Sans";
    color: rgba(175, 59, 40, 0.82);
    
    position: relative;
    width: max-content;
    max-width: 82%;
    margin: 0;
    left: 10%;
    top: 7%;
}

.event-bullet-list li {
    margin: 5px 25px;
}

.event-photo {
    position: absolute;
    right: 10%;
    top: 35%;
    height: 45%;
    max-width: 40%;
}

/* @media screen and (max-width : 1500px) {
    .event-titre {
        left: 5%;
    }
} */


@media screen and (max-width : 1350px) {
    .event-frame {
        height: 400px;
    }
    
    .event-titre {
        font-size: 45px;
        left: 5%;
    }

    .event-texte {
        font-size: 30px;
    }
    .event-bullet-list {
        font-size: 30px;
    }
    .event-photo {
        height: 50%;
        top: 45%;
    }
}


@media screen and (max-width : 1000px) {
    .event-frame {
        height: 350px;
    }
    
    .event-titre {
        font-size: 35px;
        /* left: -12%; */
    }

    .event-texte {
        font-size: 22px;
    }
    .event-bullet-list {
        font-size: 22px;
    }
    .event-photo {
        height: 47%;
        top: 40%;
    }
}


@media screen and (max-width : 800px) {
    .event-frame {
        height: 300px;
    }
    
    .event-titre {
        font-size: 28px;
        /* left: -11%; */
    }

    .event-texte {
        font-size: 17px;
    }
    .event-bullet-list {
        font-size: 17px;
    }
    .event-photo {
        height: 45%;
        top: 37%;
    }
}


@media screen and (max-width : 600px) {
    .event-frame {
        height: 350px;
    }
    
    .event-titre {
        font-size: 21px;
        /* left: -6%; */
    }

    .event-texte {
        font-size: 16px;
    }
    .event-bullet-list {
        font-size: 17px;
    }
    .event-photo {
        height: 35%;
        top: 37%;
        opacity: 0;
    }
}

@media screen and (max-width : 400px) {
    .event-frame {
        height: 350px;
    }
    
    .event-titre {
        font-size: 19px;
        /* left: -3%; */
    }

    .event-texte {
        font-size: 16px;
    }
    .event-bullet-list {
        font-size: 17px;
    }
    .event-photo {
        opacity: 0;
        height: 35%;
        bottom: 0px;
    }
}