.background {
    z-index: 10;
    top: 0px;
    width: 100vw;
}

@media screen and (min-width : 1001px) {

    .logo {
        height: 100px;
        width: 100px;
        margin-top: 10px;
        margin-left: 10px;
        left: 0px;
        position: fixed;
        cursor: pointer;
    }

    .background {
        background-color: black;
        height: 120px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: fixed;
        width: 100vw;
        top: 0px;
        left: 0px;
    }

    .titre {
        color: white;
        font-size: 70px;
        font-family: 'Josefin Sans';
        font-weight: bold;
        align-self: center;
        justify-self: center;
    }

}

@media screen and (max-width : 1000px) {

    .logo {
        height: 90px;
        width: 90px;
        margin-top: 5px;
        margin-left: 5px;
        left: 0px;
        position: fixed;
        cursor: pointer;
    }

    .background {
        background-color: black;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: fixed;
        
    }

    .titre {
        color: white;
        font-size: 60px;
        font-family: 'Josefin Sans';
        font-weight: bold;
        align-self: center;
        justify-self: center;
    }

}


@media screen and (max-width : 800px) {

    .logo {
        height: 44px;
        width: 44px;
        margin-top: 8px;
        margin-left: 3px;
        left: 0px;
        position: fixed;
        cursor: pointer;
    }

    .background {
        background-color: black;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: fixed;
        
    }

    .titre {
        color: white;
        font-size: 24px;
        font-family: 'Josefin Sans';
        font-weight: bold;
        align-self: center;
        justify-self: center;
    }

}