.btn-creation {
    background-color: rgba(255, 255, 255, 1);
    /* border: 5px solid rgb(33, 128, 142); */
    border: none;
    color: rgb(33, 128, 142);
    cursor: pointer;
    border-radius: 50px;
    position: relative;
    height: 70px;
    text-align: center;
    width: 26%;
    left: 38%;
    bottom: 90px;
    min-width: 350px;
    font-family: "Josefin Sans";
    font-weight: bold;
    font-size: 50px;
}

.btn-creation:hover {
    border: 5px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    background-color: rgba(33, 127, 142, 1);
}

.frame {
    margin-top: 120px;
    position: relative;
}

.background-image {
    z-index: 0;
}

@media screen and (min-width : 1500px) {
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 650px;
        /* object-position: center -600px; */
        z-index: 0;
    }

    .frame {
        overflow: hidden;
        height: 650px;
        width: 100%;
        position: relative;
        margin-top: 120px;
    }

    
}


@media screen and (max-width : 1499px) {
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 680px;
        /* object-position: center -230px; */
    }

    .frame {
        overflow: hidden;
        height: 680px;
        width: 100%;
        margin-top: 120;
    }
}
@media screen and (max-width: 1350px) {
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 600px;
        /* object-position: center -175px; */
    }

    .frame {
        overflow: hidden;
        height: 600px; /* Ajustez la hauteur pour le mobile */
        width: 100%;
    }

    .btn-creation {
        font-size: 35px;
        min-width: 250px;
        height: 60px;
        bottom: 80px;
    }

}


@media screen and (max-width: 1000px) {
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 500px;
        /* object-position: center -100px; */
    }

    .frame {
        overflow: hidden;
        height: 500px; /* Ajustez la hauteur pour le mobile */
        width: 100%;
        margin-top: 90px;
    }

    .btn-creation {
        font-size: 25px;
        min-width: 200px;
        height: 50px;
        bottom: 70px;
    }

}

@media screen and (max-width: 800px) { /* Adaptez la largeur maximale en fonction de vos besoins */
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 400px; /* Ajustez la hauteur pour le mobile */
        /* object-position: center -150px; Ajustez la position de l'image */
    }

    .frame {
        overflow: hidden;
        height: 400px; /* Ajustez la hauteur pour le mobile */
        width: 100%;
        margin-top: 50px;
    }

    .btn-creation {
        font-size: 130%;
        min-width: 150px;
        height: 50px;
        bottom: 70px;
    }
}

@media screen and (max-width: 600px) {
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 300px;
        /* object-position: center -80px; */
    }

    .frame {
        overflow: hidden;
        height: 300px; /* Ajustez la hauteur pour le mobile */
        width: 100%;
    }

    .btn-creation {
        font-size: 12px;
        min-width: 100px;
        height: 35px;
        bottom: 50px;
        /* border: 3px solid rgb(33, 128, 142); */
    }
    .btn-creation:hover{
        
        border: 3px solid rgb(255, 255, 255);
    }
}

@media screen and (max-width: 500px) {
    .background-image {
        width: 100%;
        object-fit: cover;
        height: 200px;
        /* object-position: center -60px; */
    }

    .frame {
        overflow: hidden;
        height: 200px;
        width: 100%;
    }
}