.taproomdesc-frame {
    height: 1500px;
    position: relative;
}

.taproomdesc-frame span {
    color:  rgba(175, 59, 40, 0.82);
}



.taproomdesc-titre {
    position: absolute;
    left : 5%;
    font-size: 60px;
    font-weight: bold;
    
}

.taproomdesc-texte-haut {
    position: relative;
    left : 0%;
    font-size: 37px;
    top : 10%;
    width: 84%;
}

.taproomdesc-bloc {
    width: 84%;
    position: relative;
    left: 8%;
    top: 15%;
    display: flex;
    flex-direction: row;
}

.taproomdesc-photo {
    position: relative;
    width: 35%;
}

.taproomdesc-horaire-container {
    text-align: center;
    display: block;
    margin: auto auto;
    
}

.taproomdesc-horaire-titre {
    font-weight: bold;
    font-size: 44px;
    position: relative;
    top: 12%;
}

.taproomdesc-horaire-texte {
    font-size: 33px;
    position: relative;
    top: 12%;
    font-style: italic;
}

.taproomdesc-horaire-texte a {
    color:  rgba(175, 59, 40, 1);
    font-weight: bold;
    text-decoration: none;
}
.taproomdesc-horaire-texte a:hover {
    color:  rgba(175, 59, 40, 0.82);
    text-decoration: underline;
}

.taproomdesc-texte-bas {
    position: relative;
    left : 0%;
    font-size: 37px;
    top : 20%;
    width: 84%;
}
@media screen and (max-width : 1500px) {
    .taproomdesc-frame {
        height: 1200px;
        
    }
}

@media screen and (max-width : 1350px) {
    .taproomdesc-titre {
        font-size: 40px;
    }

    .taproomdesc-texte-haut {
        font-size: 28px;
    }

    .taproomdesc-texte-bas {
        font-size: 28px;
    }

    .taproomdesc-horaire-titre {
        font-size: 35px;
        top: 0%;
    }

    .taproomdesc-horaire-texte {
        font-size: 28px;
        top: 0%;
    }
    .taproomdesc-photo {
        width: 40%;
    }
}


@media screen and (max-width : 1000px) {
    .taproomdesc-frame {
        height: 850px;
    }

    .taproomdesc-titre {
        font-size: 35px;
    }

    .taproomdesc-texte-haut {
        font-size: 24px;
    }

    .taproomdesc-texte-bas {
        font-size: 24px;
    }

    .taproomdesc-horaire-titre {
        font-size: 31px;
        
    }

    .taproomdesc-horaire-texte {
        font-size: 24px;
        
    }
    .taproomdesc-photo {
        width: 40%;
    }
}


@media screen and (max-width : 800px) {

    .taproomdesc-frame {
        height: 1150px;
    }

    .taproomdesc-titre {
        font-size: 35px;
    }

    .taproomdesc-bloc {
        flex-direction: column;
    }

    .taproomdesc-texte-haut {
        font-size: 22px;
    }

    .taproomdesc-texte-bas {
        font-size: 22px;
    }

    .taproomdesc-horaire-titre {
        font-size: 30px;
        top: 5%;
    }

    .taproomdesc-horaire-texte {
        font-size: 20px;
        top: 0%;
    }
    .taproomdesc-photo {
        width: 50%;
        margin: 0 auto;
    }

    .taproomdesc-horaire-container {
        margin-top: 20px;
    }
}


@media screen and (max-width : 600px) {
    .taproomdesc-titre {
        font-size: 30px;
    }

    .taproomdesc-bloc {
        flex-direction: column;
    }

    .taproomdesc-texte-haut {
        font-size: 18px;
    }

    .taproomdesc-texte-bas {
        font-size: 18px;
    }

    .taproomdesc-horaire-titre {
        font-size: 28px;
        
    }

    .taproomdesc-horaire-texte {
        font-size: 20px;
        
    }
    .taproomdesc-photo {
        width: 80%;
        margin: 0 auto;
    }

    .taproomdesc-frame {
        margin-bottom: 30px;
    }

    
}