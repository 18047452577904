.ibuwidget-texte {
    color: black;
    display: inline-flex;
    font-size: 30px;
}

.ibuwidget-colorbar-container {
    width: 500px;
    height: 40px;
    display: flex;
    
    margin-top: 10px;
    position: relative;
}

.ibuwidget-colorbar-cell {
    flex: 1;
    position: relative;
    height: 100%;
    

}

.ibuwidget-colorbar {
    position: absolute;
    align-self: center;
    height: 30px;
    width: 100%;
    background-color: #493F38;
}

.ibuwidget-colorbar-orange {
    height: 100%;
    background-color: #B54129;
    width: 50%;
}

.ibuwidget-amertume {
    color: #B54129;
    display: inline-flex;
    margin-top: 10px;
    font-size: 20px;
}


@media screen and (max-width : 1350px) {
    .ibuwidget-colorbar {
        
        height: 20px;
        
    }

    
    .ibuwidget-texte {
        font-size: 25px;
    }
    .ibuwidget-colorbar-container {
        height: 30px;
        width: 400px;
    }

    .ibuwidget-amertume {
        font-size: 15px;
    }
}

@media screen and (max-width : 1000px) {
    .ibuwidget-colorbar {
        
        height: 15px;
        
    }

    
    .ibuwidget-texte {
        font-size: 20px;
    }
    .ibuwidget-colorbar-container {
        height: 25px;
        width: 300px;
        margin-top: 5px;
    }

    .ibuwidget-amertume {
        font-size: 12px;
        margin-top: 5px;
    }
}

@media screen and (max-width : 800px) {
    .ibuwidget-colorbar {
        
        height: 10px;
        
    }

    
    .ibuwidget-texte {
        font-size: 20px;
    }
    .ibuwidget-colorbar-container {
        height: 15px;
        width: 300px;
        margin-top: 2px;
    }

    .ibuwidget-amertume {
        font-size: 17px;
        margin-top: 2px;
    }
}


@media screen and (max-width : 650px) {
    .ibuwidget-colorbar {
        
        height: 15px;
        
    }

    
    .ibuwidget-texte {
        font-size: 20px;
    }
    .ibuwidget-colorbar-container {
        height: 25px;
        width: 350px;
        margin-top: 2px;
    }

    .ibuwidget-amertume {
        font-size: 15px;
        margin-top: 2px;
    }
}

@media screen and (max-width : 500px) {
    .ibuwidget-colorbar {
        
        height: 10px;
        
    }

    
    .ibuwidget-texte {
        font-size: 20px;
    }
    .ibuwidget-colorbar-container {
        height: 15px;
        width: 250px;
        margin-top: 2px;
    }

    .ibuwidget-amertume {
        font-size: 15px;
        margin-top: 2px;
    }
}