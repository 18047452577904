.confidentialitepage-frame span {
    color : rgba(175, 59, 40, 0.82);
}

.confidentialitepage-texte {
    width: 80%;
    text-align: center;
    font-size: 22px;
}

.confidentialitepage-titre {
    text-align: center;
    font-size: 80px;
    color : rgba(175, 59, 40, 0.82);
    margin-bottom: 10px;
}

.confidentialitepage-soustitre {
    text-align: center;
    margin-top: 10px;
    font-size: 40px;
    color : rgba(175, 59, 40, 0.82);
}

@media screen and (max-width : 800px) {
    .confidentialitepage-texte {
        font-size: 19px;
    }

    .confidentialitepage-titre {
        font-size: 60px;
    }

    .confidentialitepage-soustitre {
        font-size: 30px;
    }
}

@media screen and (max-width : 500px) {
    .confidentialitepage-texte {
        font-size: 17px;
    }

    .confidentialitepage-titre {
        font-size: 47px;
    }

    .confidentialitepage-soustitre {
        font-size: 25px;
    }
}
