.renc-frame {
    height: 800px;
    width: 100%;
    overflow-y: hidden;
}

.renc-titre {
    font-size: 60px;
    font-family: "Josefin Sans";
    color: rgba(175, 59, 40, 0.82);
    font-weight: bold;
    position: relative;
    width: max-content;
    left: -28%;
    top: 5%;
    
}

.renc-container {
    display: flex;
    flex-direction: row;
    
    
}

.renc-widget {
    width: 600px;
    height: 600px;
    
    position: relative;
    top: 75px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    
}

.renc-widget-photo {
    object-fit: cover;
    height: 600px;
    width: 600px;
}

.widget-titre {
    z-index: 2;
    position: absolute;
    font-size: 70px;
    height: 80px;
    font-family: "Josefin Sans";
    font-weight: bold;
    bottom: 30px;
    width: 100%;
    transition: 0.3s;
    text-align: center;
}


.renc-widget-recto {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.renc-widget-verso {
    position: absolute;
    bottom: -100%;
    top : 100%;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    
    transition: top 0.5s;
}



.renc-widget-verso .widget-titre {
    z-index: 2;
    position: absolute;
    font-size: 70px;
    font-family: "Josefin Sans";
    font-weight: bold;
    top: 50px;
    width: 100%;
}

.widget-horaires {
    font-size: 40px;
    color: white;
    font-family: "Josefin Sans";
    font-weight: light;
    font-style: italic;
    position: relative;
    top : 25%

}

.widget-horaires a {
    color: gray;
    text-decoration: none;
}
.widget-horaires a:hover {
    color: white;
}

.renc-widget-button {
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid rgb(255, 255, 255);
    z-index: 5;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50px;
    position: absolute;
    height: 60px;
    text-align: center;
    width: 50%;
    left: 25%;
    bottom: 7%;
    
    font-family: "Josefin Sans";
    font-weight: bold;
    font-size: 35px;
}
.renc-widget-button:hover{
    background-color: rgb(255, 255, 255);
    color: black;
}

.renc-adresse {
    color: black;
    font-size: 25px;
    text-align: center;
    margin-top : 105px;
    font-style: italic;
}

@media screen and (max-width : 1350px) {
    .renc-widget {
        width: 400px;
        height: 400px;
    }

    .renc-titre {
        font-size: 45px;
    }
    .renc-frame {
        height: 600px;
        
    }
    .renc-widget-photo {
        
        height: 400px;
        width: 400px;
    }
    
    .widget-titre  {
        font-size: 45px;
    }
    .renc-widget-verso .widget-titre  {
        font-size: 45px;
    }
    .widget-horaires {
        font-size: 28px;
    }

    .renc-widget-button {
        font-size: 27px;
        height: 45px;
    }
}


@media screen and (max-width : 1000px) {
    .renc-widget {
        width: 300px;
        height: 300px;
        top: 50px;
    }

    .renc-titre {
        font-size: 35px;
        left: -28%;
        top: 4%;
    }
    .renc-frame {
        height: 440px;
        
    }

    .renc-widget-photo {
        
        height: 300px;
        width: 300px;
    }
    
    .widget-titre {
        font-size: 35px;
    }
    .renc-widget-verso .widget-titre  {
        font-size: 35px;
        top: 40px;
    }

    .widget-horaires {
        font-size: 20px;
    }
    .renc-widget-button {
        font-size: 18px;
        height: 42px;
    }

    .renc-adresse {
        
        font-size: 20px;
        
        margin-top : 65px;
    }
}


@media screen and (max-width : 800px) {
    .renc-widget {
        width: 300px;
        height: 300px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .renc-container {
        flex-direction: column;
        height: 700px;
    }

    .renc-titre {
        font-size: 28px;
        display: inline;
        left: 3%;
        top: 3%;
        
    }
    
    .renc-frame {
        height: 800px;
        
    }
    .renc-adresse {
        
        font-size: 17px;
        width: 80%;
        margin-top : 25px;
    }
}

@media screen and (max-width : 600px) {
    

    .renc-titre {
        font-size: 28px;
        
        top: 3%;
    }
    
    
}