.boutiquedesc-frame {
    height: 1100px;
    position: relative;
}

.boutiquedesc-frame span {
    color:  rgba(175, 59, 40, 0.82);
}



.boutiquedesc-titre {
    position: absolute;
    left : 5%;
    font-size: 60px;
    font-weight: bold;
    
}

.boutiquedesc-texte-haut {
    position: relative;
    left : 0%;
    font-size: 37px;
    top : 10%;
    width: 84%;
}

.boutiquedesc-bloc {
    width: 84%;
    position: relative;
    left: 8%;
    top: 15%;
    display: flex;
    flex-direction: row;
}

.boutiquedesc-photo {
    position: relative;
    width: 35%;
}

.boutiquedesc-horaire-container {
    text-align: center;
    display: block;
    margin: auto auto;
    
}

.boutiquedesc-horaire-titre {
    font-weight: bold;
    font-size: 44px;
    position: relative;
    top: 12%;
}

.boutiquedesc-horaire-texte {
    font-size: 33px;
    position: relative;
    top: 12%;
    font-style: italic;
}

.boutiquedesc-horaire-texte a {
    color:  rgba(175, 59, 40, 1);
    font-weight: bold;
    text-decoration: none;
}
.boutiquedesc-horaire-texte a:hover {
    color:  rgba(175, 59, 40, 0.82);
    text-decoration: underline;
}

.boutiquedesc-texte-bas {
    position: relative;
    left : 0%;
    font-size: 37px;
    top : 15%;
    width: 84%;
}

.boutiquedesc-texte-bas a {
    text-decoration: none;
    font-weight: bold;
    color: inherit;
}


.boutiquedesc-clickco-titre {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 45px;
}

@media screen and (max-width : 1350px) {
    .boutiquedesc-titre {
        font-size: 40px;
    }

    .boutiquedesc-texte-haut {
        font-size: 28px;
    }

    .boutiquedesc-texte-bas {
        font-size: 28px;
    }

    .boutiquedesc-horaire-titre {
        font-size: 35px;
        top: 0%;
    }

    .boutiquedesc-horaire-texte {
        font-size: 28px;
        top: 0%;
    }
    .boutiquedesc-photo {
        width: 40%;
    }

    .boutiquedesc-clickco-titre {
        
        
        font-size:35px;
    }

    
}


@media screen and (max-width : 1000px) {
    .boutiquedesc-frame {
        height: 750px;
    }

    .boutiquedesc-titre {
        font-size: 35px;
    }

    .boutiquedesc-texte-haut {
        font-size: 24px;
    }

    .boutiquedesc-texte-bas {
        font-size: 24px;
    }

    .boutiquedesc-horaire-titre {
        font-size: 31px;
        
    }

    .boutiquedesc-horaire-texte {
        font-size: 24px;
        
    }
    .boutiquedesc-photo {
        width: 40%;
    }
    .boutiquedesc-clickco-titre {
        font-size:31px;
    }
}


@media screen and (max-width : 800px) {

    .boutiquedesc-frame {
        height: 1000px;
    }

    .boutiquedesc-titre {
        font-size: 35px;
    }

    .boutiquedesc-bloc {
        flex-direction: column;
    }

    .boutiquedesc-texte-haut {
        font-size: 22px;
    }

    .boutiquedesc-texte-bas {
        font-size: 22px;
    }

    .boutiquedesc-horaire-titre {
        font-size: 30px;
        top: 5%;
    }

    .boutiquedesc-horaire-texte {
        font-size: 20px;
        top: 0%;
    }
    .boutiquedesc-photo {
        width: 50%;
        margin: 0 auto;
    }

    .boutiquedesc-horaire-container {
        margin-top: 20px;
    }

    .boutiquedesc-clickco-titre {
        font-size:28px;
    }
}


@media screen and (max-width : 600px) {
    .boutiquedesc-titre {
        font-size: 30px;
    }

    .boutiquedesc-bloc {
        flex-direction: column;
    }

    .boutiquedesc-texte-haut {
        font-size: 18px;
    }

    .boutiquedesc-texte-bas {
        font-size: 18px;
    }

    .boutiquedesc-horaire-titre {
        font-size: 28px;
        
    }

    .boutiquedesc-horaire-texte {
        font-size: 20px;
        
    }
    .boutiquedesc-photo {
        width: 80%;
        margin: 0 auto;
    }

    .boutiquedesc-frame {
        margin-bottom: 30px;
    }

    .boutiquedesc-clickco-titre {
        font-size:25px;
    }
}