.biereslistwidget-frame {
    height: fit-content;
    position: relative;
    justify-self: center;
    cursor: pointer;
    
}

.biereslistwidget-frame:hover .biereslistwidget-header {
    height: 140px;
}


.biereslistwidget-img {
    width: 400px;
}

.biereslistwidget-header {
    background-color: rgba(0, 0, 0, 0.575);
    position: absolute;
    width: 400px;
    height: 95px;
    top: 0;
    overflow: hidden;
    transition: 0.3s;
}

.biereslistwidget-header-titre {
    font-size: 40px;
    font-weight: bold;
    margin-top: 8px;
    text-align: center;
}

.biereslistwidget-header-soustitre {
    font-size: 30px;
    font-weight:300;
    margin-top: 8px;
    text-align: center;
}

.biereslistwidget-header-infos {
    font-size: 20px;
    font-style: italic;
    margin-top: 13px;
    text-align: center;
}



@media screen and (max-width : 1350px) {
    .biereslistwidget-img {
        width: 300px;
    }
    .biereslistwidget-header {
        height: 90px;
        width: 300px;
    }

    .biereslistwidget-frame:hover .biereslistwidget-header {
        height: 130px;
    }

    .biereslistwidget-header-titre {
        font-size: 35px;
    }
    .biereslistwidget-header-soustitre {
        font-size: 25px;
    }
}





@media screen and (max-width : 800px) {
    .biereslistwidget-img {
        width: 250px;
    }

    .biereslistwidget-header {
        height: 75px;
        width: 250px;
    }

    .biereslistwidget-frame:hover .biereslistwidget-header {
        height: 100px;
    }

    .biereslistwidget-header-titre {
        font-size: 30px;
    }
    .biereslistwidget-header-soustitre {
        font-size: 20px;
    }

    .biereslistwidget-header-infos {
        font-size: 15px;
    }
}

@media screen and (max-width : 600px) {
    .biereslistwidget-img {
        width: 250px;
    }

    
}