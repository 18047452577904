.lignedistrib-frame {
    margin-left: 7%;
    width: 86%;
    height: 70px;
    position: relative;
    margin-top: 0.5%;
    display: flex;
}

.lignedistrib-nom {
    font-size: 30px;
    position: absolute;
    left: 15%;
    
    align-self: center;
}


.lignedistrib-ville {
    font-size: 30px;
    position: absolute;
    right: 10%;
    
    align-self: center;
}

.lignedistrib-logo {
    position: relative;
    max-width: 10%;
    max-height: 80%;
    align-self: center;
}


@media screen and (max-width : 1350px) {
    .lignedistrib-frame {
        height: 60px;
    }
    .lignedistrib-nom {
        font-size: 25px;
    }

    .lignedistrib-ville {
        font-size: 25px;
    }
}


@media screen and (max-width : 1000px) {
    .lignedistrib-frame {
        height: 50px;
    }
    .lignedistrib-nom {
        font-size: 20px;
    }

    .lignedistrib-ville {
        font-size: 20px;
    }
}


@media screen and (max-width : 800px) {
    .lignedistrib-frame {
        height: 50px;
    }
    .lignedistrib-nom {
        font-size: 16px;
    }

    .lignedistrib-ville {
        font-size: 16px;
    }
}

@media screen and (max-width : 600px) {
    .lignedistrib-nom {
        max-width: 40%;
    }

    .lignedistrib-ville {
        max-width: 30%;
        text-align: right;
    }
}