.page-content-frame {
    width: 100%;
    margin-top: 120px;
    flex: 1;
    min-height: 90vh;
    
}

.page-bouton-retour {
    height: 90px;
    cursor: pointer;
    transform: rotate(90deg);
    border-radius: 90px;
}

span {
    color: white;
    
    font-family: "Josefin Sans";
    display: block; 
    margin: 0 auto;
}

h1 {
    display: inherit;
    font-size: inherit;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: inherit;
}

h3 {
    display: inherit;
    font-size: inherit;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: inherit;
}


@media screen and (max-width : 1000px) {
    .page-content-frame {
        
        margin-top: 100px;
       
        
    }
}


@media screen and (max-width : 800px) {
    .page-content-frame {
        
        margin-top: 60px;
       
        
    }
}