.ebcwidget-texte {
    color: black;
    display: inline-flex;
    font-size: 30px;
}

.ebcwidget-colorbar {
    width: 500px;
    height: 30px;
    display: flex;
    border: 2px solid black;
    margin-top: 10px;
    position: relative;
}

.ebcwidget-colorbar-cell {
    flex: 1;
    position: relative;
    height: 100%;
    

}

.ebcwidget-colorbar-cercle {
    position: absolute;
    height: 45px;
    width:  45px;
    align-self: center;
    border: 2px solid black;
    border-radius: 50%;
}

@media screen and (max-width : 1350px) {
    .ebcwidget-colorbar {
        width: 400px;
        height: 20px;
        margin-top: 5px;
    }

    .ebcwidget-colorbar-cercle {
        height: 30px;
        width: 30px;
    }
    .ebcwidget-texte {
        font-size: 25px;
    }
    
}


@media screen and (max-width : 1000px) {
    .ebcwidget-colorbar {
        width: 300px;
        height: 15px;
        margin-top: 5px;
    }

    .ebcwidget-colorbar-cercle {
        height: 25px;
        width: 25px;
    }
    .ebcwidget-texte {
        font-size: 20px;
    }
}


@media screen and (max-width : 800px) {
    .ebcwidget-colorbar {
        width: 300px;
        height: 10px;
        margin-top: 5px;
    }

    .ebcwidget-colorbar-cercle {
        height: 20px;
        width: 20px;
    }
    .ebcwidget-texte {
        font-size: 20px;
    }
}

@media screen and (max-width : 650px) {
    .ebcwidget-colorbar {
        width: 350px;
        height: 15px;
        margin-top: 5px;
    }

    .ebcwidget-colorbar-cercle {
        height: 25px;
        width: 25px;
    }
    .ebcwidget-texte {
        font-size: 20px;
    }
}

@media screen and (max-width : 500px) {
    .ebcwidget-colorbar {
        width: 250px;
        height: 10px;
        margin-top: 5px;
    }

    .ebcwidget-colorbar-cercle {
        height: 20px;
        width: 20px;
    }
    .ebcwidget-texte {
        font-size: 20px;
    }
}