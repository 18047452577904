.mentionspage-frame span {
    color: rgba(175, 59, 40, 0.82);
}

.mentionspage-texte {
    font-size: 25px;
    text-align: center;
    /* margin-left: 50px;
    width: 80%; */
}

.mentionspage-titre {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    
}

.mentionspage-gras {
    font-weight: bold;
    font-size: 30px;
}