.visitpage-frame span{
    color: black;
}

.visitpage-frame {
    position: relative;
    width: 100%;
}

.visitpage-titre {
    font-weight: bold;
    font-size: 65px;
    text-align: center;

}

.visitpage-container-texte {
    width:90%;
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
}

.visitpage-texte {
    position: relative;
    width: 60%;
    left: 0%;
    font-size: 40px;
    display:block;
    margin: 0;
    line-height: 1.25;
}

.visitpage-img {
    position: absolute;
    right: 5%;
    max-width: 30%;
}

.visitpage-soustitre {
    font-weight: bold;
    position: relative;
    font-size: 55px;
    text-align: left;
    margin: 4% 0 0 5%;
    

}


@media screen and (max-width : 1700px) {
    .visitpage-texte {
        font-size: 30px;
    }

    .visitpage-img {
        right: 2%;
        max-width: 35%;
    }

}

@media screen and (max-width : 1350px) {
    .visitpage-texte {
        font-size: 25px;
    }

    .visitpage-titre {
        font-size: 50px;
    }
    .visitpage-soustitre {
        font-size: 40px;
    }
}


@media screen and (max-width : 1000px) {
    .visitpage-texte {
        font-size: 20px;
    }

    .visitpage-titre {
        font-size: 45px;
    }
    .visitpage-soustitre {
        font-size: 35px;
    }
}


@media screen and (max-width : 800px) {
    .visitpage-texte {
        font-size: 15px;
    }

    .visitpage-titre {
        font-size: 40px;
    }
    .visitpage-soustitre {
        font-size: 30px;
    }

}

@media screen and (max-width : 600px) {
    .visitpage-texte {
        font-size: 15px;
        width: 100%;
    }
    .visitpage-container-texte {
        flex-direction: column;
    }

    .visitpage-titre {
        font-size: 40px;
    }
    .visitpage-soustitre {
        font-size: 30px;
    }

    .visitpage-img {
        position: relative;
        max-width: 80%;
        margin: 5% auto 0 auto;
    }
    

}
    
    