.bierePDpage-blocHaut {
    height: 750px;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.bierePDpage-image {
    position: relative;
    width: 100%;
    transform: translateY(-250px);
}

#imgtop {
    left: 0px;
}

#imgbottom {
    right: 0px;
}


.bierePDpage-imgcontainer {
    width: 70%;
    max-width: 850px;
    overflow: hidden;
    height: 100%;
    position: relative;
    
}

.bierePDpage-blocHaut-container {
    width: 100%;
    height: 100%;

}

.bierePDpage-blocHaut-titre {
    font-weight: bold;
    text-align: center;
    font-size: 110px;
    color: rgba(175, 59, 40, 0.82);
    margin: 0;
    position: relative;
    margin-top: 2%;

}


.bierePDpage-blocHaut-style {
    font-weight: bold;
    position: relative;
    text-align: center;
    font-size: 70px;
    color: #B49F9C;
    margin: 0;
    margin-block: 0;
    
}

.bierePDpage-blocHaut-shortdesc {
    position: relative;
    width: 70%;
    color: black;
    font-size: 35px;
    text-align: center;
    font-weight: 300;
    margin-top: 10px;
}

.bierePDpage-blocHaut-bouton{
    margin: 50px auto 0 auto;
    display: block;
    width: max-content;
    font-size: 45px;
    font-weight: bold;
    text-decoration: none;
    color : #21808E;
    border: 5px solid #21808E;
    padding: 10px;
    transition: 0.3s;
    text-align: center;
    border-radius: 30px;
}

.bierePDpage-blocHaut-bouton:hover {
    background-color : #21808E;
    color: white;
}

.bierePDpage-longdesc {
    color: black;
    font-weight: 300;
    text-align: center;
    margin-top: 80px;
    font-size: 40px;
    position: relative;
    font-style: italic;
    width: 68%;
    margin-bottom: 25px;
    line-height: 150%;
}

.bierePDpage-blocHaut-carac {
    font-size: 80px;
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    color: rgba(175, 59, 40, 0.82);;
}

.bierePDpage-blocHaut-ingredient-ligne {
    color: black;
    font-weight: 300;
    display: inline-flex;
    font-size: 40px;
}

.bierePDpage-blocHaut-ingredients {
    width: fit-content;
    margin: 0 auto 30px auto;
}

.bierePDpage-blocHaut-widgetcontainer {
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
}

.bierePDpage-blocHaut-soustexte {
    color: black;
    text-align: center;
    margin-top: 30px;
    font-size: 25px;
    font-weight: 300;
}


@media screen and (max-width : 1500px) {
    .bierePDpage-blocHaut-carac {
        font-size: 70px;
        margin-bottom: 30px;
    }
    .bierePDpage-blocHaut-ingredient-ligne {
        font-size: 35px;
        
    }
}


@media screen and (max-width : 1350px) {
    .bierePDpage-image {
        transform: translateY(-200px);
    }

    .bierePDpage-blocHaut-titre {
        font-size: 80px;
    }
    .bierePDpage-blocHaut-style {
        font-size: 50px;
    }
    .bierePDpage-blocHaut-shortdesc {
        font-size: 30px;
    }
    .bierePDpage-blocHaut-bouton {
        font-size: 35px;
        margin-top: 25px;
    }
    .bierePDpage-blocHaut {
        height: 450px;
    }

    .bierePDpage-blocHaut-carac {
        font-size: 50px;
        margin-top: 20px;
    }
    .bierePDpage-blocHaut-ingredient-ligne {
        font-size: 24px;
        margin-top: 2px;
    }

    .bierePDpage-blocHaut-soustexte {
        font-size: 20px;
    }

    .bierePDpage-longdesc {
        font-size: 32px;
    }
}


@media screen and (max-width : 1000px) {
    .bierePDpage-image {
        transform: translateY(-150px);
    }

    .bierePDpage-blocHaut-titre {
        font-size: 60px;
    }
    .bierePDpage-blocHaut-style {
        font-size: 40px;
    }
    .bierePDpage-blocHaut-shortdesc {
        font-size: 20px;
    }
    .bierePDpage-blocHaut-bouton {
        font-size: 25px;
        margin-top: 25px;
        padding: 7px;
    }
    .bierePDpage-blocHaut {
        height: 350px;
    }

    .bierePDpage-blocHaut-carac {
        font-size: 40px;
        margin-top: 20px;
    }
    .bierePDpage-blocHaut-ingredient-ligne {
        font-size: 20px;
        margin-top: 2px;
    }

    .bierePDpage-blocHaut-soustexte {
        font-size: 15px;
        margin-top: 15px;
    }

    .bierePDpage-longdesc {
        font-size: 25px;
        margin-top: 60px;
    }
}


@media screen and (max-width : 800px) {
    .bierePDpage-image {
        transform: translateY(-100px);
    }

    .bierePDpage-blocHaut-titre {
        font-size: 50px;
    }
    .bierePDpage-blocHaut-style {
        font-size: 30px;
    }
    .bierePDpage-blocHaut-shortdesc {
        font-size: 15px;
    }
    .bierePDpage-blocHaut-bouton {
        font-size: 17px;
        margin-top: 25px;
        padding: 7px;
    }
    .bierePDpage-blocHaut {
        height: 300px;
    }

    .bierePDpage-blocHaut-carac {
        font-size: 30px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .bierePDpage-blocHaut-ingredient-ligne {
        font-size: 15px;
        margin-top: 2px;
    }

    .bierePDpage-blocHaut-soustexte {
        font-size: 12px;
        margin-top: 15px;
    }

    .bierePDpage-longdesc {
        font-size: 20px;
        margin-top: 60px;
    }
}


@media screen and (max-width : 650px) {
    .bierePDpage-image {
        transform: translateY(0px);
    }

    .bierePDpage-blocHaut-titre {
        font-size: 75px;
    }

    .bierePDpage-blocHaut-container {
        height: 60%;
    }

    .bierePDpage-imgcontainer {
        margin: 0 auto;
        width: 75%;
    }

    #imgtop {
        left: auto;
        justify-self: center;

    }

    #imgbottom {
        right: auto;
    }
    .bierePDpage-blocHaut-style {
        font-size: 35px;
    }
    .bierePDpage-blocHaut-shortdesc {
        font-size: 20px;
    }
    .bierePDpage-blocHaut-bouton {
        font-size: 17px;
        margin-top: 25px;
        padding: 7px;
        margin-bottom: 20px;
    }
    .bierePDpage-blocHaut {
        height: fit-content;
        flex-direction: column;
        
    }

    .bierePDpage-blocHaut-carac {
        font-size: 50px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .bierePDpage-blocHaut-ingredient-ligne {
        font-size: 22px;
        margin-top: 2px;
    }

    .bierePDpage-blocHaut-soustexte {
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    .bierePDpage-longdesc {
        font-size: 20px;
        margin-top: 60px;
    }
}


@media screen and (max-width : 500px) {
    .bierePDpage-blocHaut-titre {
        font-size: 55px;
    }

    .bierePDpage-blocHaut-container {
        height: 60%;
    }

    .bierePDpage-imgcontainer {
        margin: 0 auto;
        width: 75%;
    }

    
    .bierePDpage-blocHaut-style {
        font-size: 35px;
    }
    .bierePDpage-blocHaut-shortdesc {
        font-size: 20px;
    }
    .bierePDpage-blocHaut-bouton {
        font-size: 17px;
        margin-top: 25px;
        padding: 7px;
        margin-bottom: 20px;
    }
    

    .bierePDpage-blocHaut-carac {
        font-size: 35px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .bierePDpage-blocHaut-ingredient-ligne {
        font-size: 17px;
        margin-top: 2px;
    }

    .bierePDpage-blocHaut-soustexte {
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    .bierePDpage-longdesc {
        font-size: 17px;
        margin-top: 30px;
    }
}